// Wrapper over route to check the session id and check auth of the user
// all the things needed at the time of component call...

import React from "react";
import { Redirect, Route } from "react-router-dom";

import SideBar from "../src/Layouts/SideBar";

const PrivateRoute = (props) => {
  const { component, ...rest } = props;  
  const sessionId = localStorage.getItem("sessionId");  
  if (sessionId) {
    return (
      <SideBar>
        <Route component={component} {...rest} />
      </SideBar>
    );
  } else {
    return <Redirect to={`/`} />;
  }
};

export default PrivateRoute;
