import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

// import {UserReducer} from "./usersReducer"
// import {AuthReducer} from "./authReducer"

import UserReducer from "./usersReducer";
import AuthReducer from "./authReducer"

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    users: UserReducer,    
    auth: AuthReducer    
    // rest of your reducers
  });
export default createRootReducer;