import {
    GET_ALL_USERS,
    USER_ERROR,
    USER_LOADING    
  } from "../ActionTypes";
  
  const initialState = {
    users: [],
    loading: false,
    error: {},
  };

  const UserReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_ALL_USERS:
        return {
          ...state,
          loading: false,
          users: action.payload,
        };
      case USER_LOADING:
        return {
          ...state,
          loading: true,
        };
      case USER_ERROR:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };           
      default:
        return state;
    }
  };
  
  export default UserReducer;