import React, { Component } from "react";
import { Layout, Menu, Popover, notification } from "antd";
import { RightOutlined, MoreOutlined } from "@ant-design/icons";
import "./layout.css";
import KuberLogo from "Assets/kuberlogo.svg";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import GetSelectedRoute from "Utils/getDefaultSelectedRoute";
import userAvatar from "Assets/dummyAvatar.jpg";
import axios from "AxiosInstance";
import Loader from "./Loader";

class SideBar extends Component{
  state = {
    collapse: false,
    currentUser: null,
    userFound: false,
    isSessionLoggedOut: false,
  };

  componentDidMount = () =>{
    this.setState({ userFound: true });
  }

  onLogoutUser = () => {
    const sessionId = localStorage.getItem("sessionId");
    axios.post("/session/logout", {}, { headers: { sessionId: sessionId } });
  };

  render(){

    const { Sider, Content, Header } = Layout;
    const { SubMenu } = Menu;
    const {
      collapse,
      currentUser,
      isSessionLoggedOut,
      userFound,
      userManagement,
      productManagement,
      supplierManagement,
      contentManagement,
      couponManagement,
      orderManagement,
      generalSettingsManagement,
      customerManagement,
    } = this.state;

    const languagecontent = (
      <div className="lg_list">
        English (UK)
        {/* <Link href="#">English (UK)</Link> */}
      </div>
    );
    const currencycontent = (
      <div className="lg_list">
        GBP
        {/* <Link href="#">GBP</Link> */}
      </div>
    );

    if (isSessionLoggedOut) {
      return (
        <Redirect to={`/`} />
      );
    }
    if (!userFound) {
      return <Loader />;
    }

    return(
      <Layout hasSider={true}>
        <Sider
          width={240}
          trigger={
            <div>
              <RightOutlined style={{ color: "#EB4A1C" }} />
            </div>
          }
          className={collapse ? "responsiveSider" : "fixedSider"}
          breakpoint="md"
          collapsedWidth="0"
          onBreakpoint={(broken) => {
            if (broken) this.setState({ collapse: true });
            else this.setState({ collapse: false });
          }}
        >
           <div className="kuber-logo-container">
            <img src={KuberLogo} className="logo" alt="logo" />
          </div>
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={GetSelectedRoute(this.props.pathname)}
            className="kuber_leftbar_menu"
          >
            <Menu.Item
              key="1"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span className="sub_icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path className="db_icon" d="M0,0H24V24H0Z" />
                  <circle
                    className="b"
                    cx="9"
                    cy="9"
                    r="9"
                    transform="translate(3 3)"
                  />
                  <circle
                    className="b"
                    cx="1"
                    cy="1"
                    r="1"
                    transform="translate(11 11)"
                  />
                  <line
                    className="b"
                    y1="2.59"
                    x2="2.59"
                    transform="translate(13.41 8)"
                  />
                  <path className="b" d="M7,12a5,5,0,0,1,5-5" />
                </svg>
                <Link to="/dashboard">Dashboard</Link>
              </span>
            </Menu.Item>
            <SubMenu
              key="sub2"
              title={
                <span className="sub_icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="Group_301"
                    data-name="Group 301"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      id="Path_387"
                      data-name="Path 387"
                      className="db_icon"
                      d="M0,0H24V24H0Z"
                    />
                    <rect
                      id="Rectangle_731"
                      data-name="Rectangle 731"
                      className="b"
                      width="14"
                      height="18"
                      rx="2"
                      transform="translate(5 3)"
                    />
                    <line
                      id="Line_15"
                      data-name="Line 15"
                      className="b"
                      x2="6"
                      transform="translate(9 7)"
                    />
                    <line
                      id="Line_16"
                      data-name="Line 16"
                      className="b"
                      x2="6"
                      transform="translate(9 11)"
                    />
                    <line
                      id="Line_17"
                      data-name="Line 17"
                      className="b"
                      x2="4"
                      transform="translate(9 15)"
                    />
                  </svg>
                  <span>Merchant</span>
                </span>
              }
            >
            <Menu.Item key="2">
              <Link to="/merchant/list">Merchant</Link>
            </Menu.Item>
            </SubMenu>
            <SubMenu
              key="sub3"
              title={
                <span className="sub_icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="Group_301"
                    data-name="Group 301"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      id="Path_387"
                      data-name="Path 387"
                      className="db_icon"
                      d="M0,0H24V24H0Z"
                    />
                    <rect
                      id="Rectangle_731"
                      data-name="Rectangle 731"
                      className="b"
                      width="14"
                      height="18"
                      rx="2"
                      transform="translate(5 3)"
                    />
                    <line
                      id="Line_15"
                      data-name="Line 15"
                      className="b"
                      x2="6"
                      transform="translate(9 7)"
                    />
                    <line
                      id="Line_16"
                      data-name="Line 16"
                      className="b"
                      x2="6"
                      transform="translate(9 11)"
                    />
                    <line
                      id="Line_17"
                      data-name="Line 17"
                      className="b"
                      x2="4"
                      transform="translate(9 15)"
                    />
                  </svg>
                  <span>Users</span>
                </span>
              }
            >
              <Menu.Item key="3">
                <Link to="/user/list">Users</Link>
              </Menu.Item>
            </SubMenu>
          </Menu>
          <div className="user_profile">
            <div className="user_hv">
              <div className="user_inner">
                <div className="user_img">
                  <img src={userAvatar} className="user_in_img" alt="logo" />
                </div>
                <div className="user_dsc">
                  <span>
                  {/* {currentUser.firstName} {currentUser.lastName} */}
                  </span>
                  <p>Administrator</p>
                </div>
                <div className="user_icon">
                  <MoreOutlined />
                </div>
              </div>
              <div className="user_link">
                <Link
                  to={`/`}
                  onClick={this.onLogoutUser}
                >
                  Log out
                </Link>
              </div>
            </div>
          </div>
        </Sider>   
        <Layout
          style={{
            marginLeft: collapse ? 0 : 240,
            background: "#F5F7FA",
          }}
          className="main_layout"
        >

          <Header className="top_bar_main">
            <div className="search-bar">
              {/* <Search placeholder="Search in Kuber" /> */}
            </div>

            <div className="top_right_bar">
              <ul>                
                <li>
                  <div className="set_bx">
                    <Popover
                      placement="bottomLeft"
                      content={languagecontent}
                      trigger="click"
                    >
                      <svg
                        id="Group_4118"
                        data-name="Group 4118"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          id="Path_4407"
                          data-name="Path 4407"
                          d="M0,0H24V24H0Z"
                          fill="none"
                        />
                        <path
                          id="Path_4408"
                          data-name="Path 4408"
                          d="M5,7h7M10,5V7c0,4.418-2.239,8-5,8m1-4c0,2.144,2.952,3.908,6.7,4"
                          fill="none"
                          stroke="#2f373b"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                        <path
                          id="Path_4409"
                          data-name="Path 4409"
                          d="M11,19l4-9,4,9m-.9-2H11.9"
                          fill="none"
                          stroke="#2f373b"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                      </svg>
                      <span>English (UK)</span>
                    </Popover>
                  </div>
                </li>
                <li>
                  <div className="set_bx">
                    <Popover
                      placement="bottomLeft"
                      content={currencycontent}
                      trigger="click"
                    >
                      <svg
                        id="Group_4121"
                        data-name="Group 4121"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          id="Path_4410"
                          data-name="Path 4410"
                          d="M0,0H24V24H0Z"
                          fill="none"
                        />
                        <rect
                          id="Rectangle_1506"
                          data-name="Rectangle 1506"
                          width="14"
                          height="10"
                          rx="2"
                          transform="translate(7 9)"
                          fill="none"
                          stroke="#2f373b"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                        <circle
                          id="Ellipse_186"
                          data-name="Ellipse 186"
                          cx="2"
                          cy="2"
                          r="2"
                          transform="translate(12 12)"
                          fill="none"
                          stroke="#2f373b"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                        <path
                          id="Path_4411"
                          data-name="Path 4411"
                          d="M17,9V7a2,2,0,0,0-2-2H5A2,2,0,0,0,3,7v6a2,2,0,0,0,2,2H7"
                          fill="none"
                          stroke="#2f373b"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                      </svg>
                      <span>GBP</span>
                    </Popover>
                  </div>
                </li>
              </ul>
            </div>
          </Header>
          <Content className={collapse ? "mobileContent" : "content all_pd"}>
            <div>{this.props.children}</div>            
          </Content>
        </Layout>     
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {  
  return {
    pathname: state.router.location.pathname,
  };
};

export default connect(mapStateToProps,{})(SideBar);