export const LOGIN_USER = "LOGIN_USER";

export const GET_ALL_USERS = "GET_ALL_USERS";
export const USER_LOADING = "USER_LOADING";
export const USER_ERROR = "USER_ERROR";

export const GET_USER_ROLE = "GET_USER_ROLE";

export const GET_ALL_MERCHANT = "GET_ALL_MERCHANT";
export const MERCHANT_LOADING = "MERCHANT_LOADING";
export const MERCHANT_ERROR = "MERCHANT_ERROR";