import React, { Component, Suspense } from "react";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import "antd/dist/antd.less"; 
import PrivateRoute from "./PrivateRoute";
import "./custom.css";
import store, { history } from "./store";
import Loader from "./Layouts/Loader";
import ErrorBoundary from "./Components/ErrorBoundary";
import CacheBuster from "./Components/cacheBuster"
const Dashboard = React.lazy(() => import("Screens/Dashboard/Dashboard"));
const UserList = React.lazy(() => import("Screens/Users/UserList"));
const AddUser = React.lazy(() => import("Screens/Users/AddUser"));
const MerchantList = React.lazy(() => import("Screens/Merchants/MerchantList"));
const AddMerchant = React.lazy(() => import("Screens/Merchants/AddMerchant"));
const ViewMerchant = React.lazy(() => import("Screens/Merchants/ViewMerchant"));

const loginPage = React.lazy(() => import("Screens/Login/Login"));
export default class App extends Component {
  render(){
    return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          // You can decide how and when you want to force reload
          refreshCacheAndReload();
        }
        return(          
          <Provider store={store}>
          <ConnectedRouter history={history}>
            <ErrorBoundary>
              <Suspense fallback={<Loader />}>
              <Switch>
                <Route exact path="/" component={loginPage} />
                <PrivateRoute
                  exact
                  path="/dashboard"
                  component={Dashboard}
                />
                 <PrivateRoute
                  exact
                  path="/user/list"
                  component={UserList}
                />
                <PrivateRoute
                  exact
                  path="/user/add"
                  component={AddUser}
                />
                <PrivateRoute
                  exact
                  path="/user/edit/:id"
                  component={AddUser}
                />
                  <PrivateRoute
                  exact
                  path="/merchant/list"
                  component={MerchantList}
                />
                 <PrivateRoute
                  exact
                  path="/merchant/add"
                  component={AddMerchant}
                />
                <PrivateRoute
                  exact
                  path="/merchant/view/:id"
                  component={ViewMerchant}
                />
              </Switch>
            </Suspense> 
            </ErrorBoundary>
            </ConnectedRouter>
            </Provider>
                     
        );
      }}
    </CacheBuster>
    );
  }  
}
