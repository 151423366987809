//Define all the routes here and return key in this manner to get default selected route in side bar navigation

const getDefaultSelectedRoute = (pathname) => {
  switch (pathname) {
    case "/dashboard":
      return ["1"];   
    case "/merchant/list":
      return ["2"];
    case "/user/list":
      return ["3"]; 
    default:
      return ["1"];
  }
};

export default getDefaultSelectedRoute;
